import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span style={{
      "fontWeight": "400"
    }}>
  [Dashbot](https://www.dashbot.io/?utm_medium=medium&utm_campaign=VLannouncement&utm_content=PR)
  is excited to welcome and support VoiceLabs Insights users as VoiceLabs
  transitions out of analytics, and Dashbot continues to grow in this space.
  Dashbot has provided cross platform analytics for developers building Alexa
  Skills, Google Actions, Facebook chatbots, and other conversational interfaces
  since 2016, and has processed more than 23 billion messages to date. VoiceLabs
  will formally discontinue VoiceInsights, their voice analytics product, on
  March 29, 2018, giving existing users time to export and migrate their data.{" "}
    </span>
    <span style={{
      "fontWeight": "400"
    }}>
  The VoiceLabs team selected Dashbot as a preferred partner for VoiceInsights
  customers to migrate their data and begin using promptly. Dashbot is the
  leading analytics service that supports all conversational interfaces by
  providing advanced reports and premium tools for developers and brands alike
  to understand and optimize the bot user experience. Dashbot is excited to
  welcome VoiceLabs customers and provide resources and support to make the
  transition seamless so that new users can have immediate access to
  conversational analytics and tools.{" "}
    </span>
    <span style={{
      "fontWeight": "400"
    }}>
  Dashbot has built a simple importing tool so VoiceLabs customers can easily
  transfer their conversational products and data, which will remain perfectly
  intact during this transition. New customers will have complete access to the
  full suite of Dashbot reports and tools to continue building, understanding,
  and optimizing their applications.
    </span>
    <span style={{
      "fontWeight": "400"
    }}>
  This transition is timely given the growth and development of third-party apps
  and tools that voice platforms have recently announced. In December 2017,
  Alexa surpassed 25,000 Skills. More notably, at CES, Amazon announced that
  they added Windows 10 PC to the lengthy list of devices that will support
  Alexa and fight for voice share with Cortana’s 141 million monthly users. The
  Google Assistant also made its mark at CES with the announcement of
  integrations with several hardware partners including JBL, Lenovo, LG and
  Sony. The conversational interface space continues to rapidly grow, and
  Dashbot is in a prime position to help developers and brands build optimal
  user experiences as they invest more resources into this space.{" "}
    </span>
    <span style={{
      "fontWeight": "400"
    }}>
  Arte Merritt, CEO &amp; co-founder of Dashbot, shares his thoughts on working
  with VoiceLabs during this transition: “
    </span>
*
    <span style={{
      "fontWeight": "400"
    }}>
  We’re excited to welcome VoiceLabs customers to our platform and work with
  them to help optimize their voice experiences. We appreciate Adam and team’s
  contributions to the space and look forward to seeing their future endeavor.
    </span>
*<span style={{
      "fontWeight": "400"
    }}>”</span>
    <span style={{
      "fontWeight": "400"
    }}>
  For instructions on how to export your data and import it to Dashbot, please
  visit: <a href="https://www.dashbot.io/voicelabs" />.{" "}
    </span>
    <span style={{
      "fontWeight": "400"
    }}>
  For any additional questions, please reach Dashbot at contact@dashbot.io.
    </span>
    <h2><span style={{
        "fontWeight": "400"
      }}>{`About `}</span><a parentName="h2" {...{
        "href": "https://www.dashbot.io/?utm_medium=medium&utm_campaign=VLannouncement&utm_content=PR"
      }}><span style={{
          "fontWeight": "400"
        }}>{`Dashbot`}</span></a></h2>
    <p><em parentName="p"><span style={{
          "fontWeight": "400"
        }}><a parentName="em" {...{
            "href": "https://www.dashbot.io/?utm_medium=medium&utm_campaign=VLannouncement&utm_content=PR"
          }}>{`Dashbot`}</a>{` is the leading conversational analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools. In addition to traditional analytics like engagement and retention, we provide bot and voice specific metrics including sentiment analysis, conversational analytics, and full chat session transcripts. We also have tools to take action on the data, like our live-person takeover, broadcast messaging, and goal funnels.`}</span></em></p>
    <p><em parentName="p"><span style={{
          "fontWeight": "400"
        }}>{`Dashbot was founded in April 2016 by serial entrepreneurs Arte Merritt, an MIT alum who sold his previous mobile analytics company to Nokia, Jesse Hull an MIT alum who sold his startup to Game Show Network, and Dennis Yang a Cornell alum who sold his previous company to eBay. `}</span></em></p>
    <p><em parentName="p"><span style={{
          "fontWeight": "400"
        }}>{`Dashbot is funded by ffVC, Bessemer, Samsung Next, BDMI, Scrum Ventures, Rembrandt, Recruit, and R/GA.`}</span></em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      